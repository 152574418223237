<template>
  <div class="my-profile-form flex flex-col w-full">
    <h1 class="title ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('MY_PROFILE.TITLE') }}
    </h1>
    <form class="mt-33 flex-auto">
      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900" for="inline-full-name">
            {{ $t('MY_PROFILE.FORM.PHONE.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="phone"
            :error="$v.phone.$invalid && $v.phone.$dirty"
            :maxlength="9"
            :placeholder="$t('MY_PROFILE.FORM.PHONE.PLACEHOLDER')"
            @input="$v.phone.$touch()"
          />
          <p
            v-if="$v.phone.$invalid && $v.phone.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('MY_PROFILE.FORM.PHONE.ERROR') }}
          </p>
        </div>
        <div
          class="buttons w-1/3 max-w-lg flex-auto bg-blue-400 text-15 font-semibold mr-62 cursor-pointer text-center flex justify-center items-center"
          @click="sendPhone()"
        >
          <a class="text-15 font-medium leading-160 text-white border-none rounded-1">
            {{ $t('MY_PROFILE.FORM.PHONE.SEND_PHONE') }}
          </a>
        </div>
      </div>
      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900" for="inline-full-name">
            {{ $t('MY_PROFILE.FORM.EMAIL.LABEL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="email"
            :error="$v.email.$invalid && $v.email.$dirty"
            :placeholder="$t('MY_PROFILE.FORM.EMAIL.PLACEHOLDER')"
            @input="$v.email.$touch()"
          />
          <p
            v-if="$v.email.$invalid && $v.email.$dirty"
            class="error-message text-red-500 text-15 mt-12"
          >
            {{ $t('MY_PROFILE.FORM.EMAIL.ERROR') }}
          </p>
        </div>
        <div
          class="buttons w-1/3 max-w-lg flex-auto bg-blue-400 text-15 font-semibold mr-62 cursor-pointer text-center flex justify-center items-center"
          @click="sendEmail()"
        >
          <a class="text-15 font-medium leading-160 text-white border-none rounded-1">
            {{ $t('MY_PROFILE.FORM.EMAIL.SEND_EMAIL') }}
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapMutations } from 'vuex'
import employeeService from '@/shared/services/employeeService'
import AppInput from '@/components/form/AppInput'
import PhoneValidation from '@/components/modals/PhoneValidation'
import EmailValidation from '@/components/modals/EmailValidation'
import Alert from '@/components/modals/Alert'
import { emailFormatter } from '@/shared/utils/emailFormatter'

export default {
  name: 'MyProfileForm',
  components: {
    AppInput,
  },
  mixins: [validationMixin],
  data: () => ({
    phone: undefined,
    email: undefined,
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['maskedPhone', 'maskedEmail']),
  },
  validations: {
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
    },
    email: {
      required,
      email: (value) => email(emailFormatter(value)),
    },
  },
  created() {
    this.phone = this.user.validationPhone
    this.email = this.user.validationEmail
  },
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    sendPhone() {
      if (this.$v.phone.$invalid) {
        return
      }

      this.setRequestInProgressStatus(true)
      employeeService.registerPhone(this.phone)
      this.user.validationPhone = this.phone
      this.openPhoneValidationModal(this.phone)
      this.setRequestInProgressStatus(false)
    },
    openPhoneValidationModal(phone) {
      const props = {
        maskedPhone: this.maskedPhone,
        phone,
        translationKeys: {
          title: 'MODALS.PHONE_VALIDATION.PROFILE_TITLE',
        },
      }
      this.setRequestInProgressStatus(true)
      this.$modal
        .open(PhoneValidation, props)
        .then((response) => {
          if (response.validated) {
            this.openPhoneUpdateSuccessModal()
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    openPhoneUpdateSuccessModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.PHONE_UPDATE_SUCCESS.TITLE',
          text: 'MODALS.PHONE_UPDATE_SUCCESS.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
    sendEmail() {
      if (this.$v.email.$invalid) {
        return
      }

      this.setRequestInProgressStatus(true)
      employeeService.registerEmail(this.email)
      this.user.validationEmail = this.email
      this.openEmailValidationModal(this.email)
      this.setRequestInProgressStatus(false)
    },
    openEmailValidationModal(email) {
      const props = {
        maskedEmail: this.maskedEmail,
        email,
        translationKeys: {
          title: 'MODALS.EMAIL_VALIDATION.PROFILE_TITLE',
        },
      }

      this.setRequestInProgressStatus(true)
      this.$modal
        .open(EmailValidation, props)
        .then((response) => {
          if (response.validated) {
            this.openEmailUpdateSuccessModal()
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    openEmailUpdateSuccessModal() {
      const props = {
        translationKeys: {
          title: 'MODALS.EMAIL_UPDATE_SUCCESS.TITLE',
          text: 'MODALS.EMAIL_UPDATE_SUCCESS.TEXT',
        },
      }

      this.$modal.open(Alert, props)
    },
  },
}
</script>

<style lang="scss" scoped>
.my-profile-form {
  .buttons {
    max-height: 49px;
  }
}
</style>
