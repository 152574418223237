<template>
  <app-section class="my-profile animated fadeIn">
    <div class="title flex-initial my-32 pb-16 text-46 font-semibold text-grey-900">
      {{ $t('MY_PROFILE.TITLE') }}
    </div>
    <div class="description text-20 text-grey-500 pb-38">
      {{ $t('MY_PROFILE.TEXT') }}
    </div>

    <a v-if="false" :href="legalHref" target="_blank" class="flex justify-end">
      <information :text="$t('INFORMATION.DATA_PROTECTION_LAW')" class="mt-38" />
    </a>

    <forms-with-steps ref="forms" :steps="steps" />
  </app-section>
</template>

<script>
import FormsWithSteps from '@/components/forms-with-steps/FormsWithSteps'
import MyProfileForm from './components/MyProfileForm'
import AppSection from '@/components/AppSection'
import Information from '@/components/Information'
import { isDcUrl } from '@/shared/utils/isDcUrl'

export default {
  name: 'MyProfile',
  components: {
    FormsWithSteps,
    AppSection,
    Information,
  },

  data: () => ({
    steps: [
      {
        title: 'MY_PROFILE.TITLE',
        component: MyProfileForm,
      },
    ],
  }),

  computed: {
    legalHref() {
      return isDcUrl() ? '/legal/dc-aviso-legal.pdf' : '/legal/senior-aviso-legal.pdf'
    },
  },
}
</script>
